.labeling {
    display: flex;
    flex-direction: column;
    height: 100vh;
    width: 100vw;
    gap: 0.5rem;
    margin: 0;
    padding: 0;

    .labeling-header {
        height: 2.5rem;
        margin: 0.5rem 0.5rem 0 0.5rem;
        display: flex;
        flex-direction: row;
        align-items: center;

        .labeling-back {
            width: 3rem;
            height: 100%;
            border-radius: 5px;
            margin-right: 1rem;
            background-color: rgba(white, 0.05);

            display: flex;
            justify-content: flex-start;
            align-items: center;
            box-sizing: border-box;

            &:hover {
                background-color: rgba(255, 255, 255, 0.08)
            }
            &:active {
                background-color: rgba(255, 255, 255, 0.1)
            }
        }

        .labeling-back-icon {
            height: 60%;
            margin-left: 0.5rem;
        }

        .labeling-title {
            font-weight: 600;
            margin: 0;
            font-size: 1.5rem;
            line-height: 2rem;
            overflow: hidden;
            word-break: break-all;
        }
    }

    .labeling-requests-container {
        max-height: calc(100% - 3rem);
        display: flex;
        overflow-x: hidden;
        overflow-y: auto;
    }
}